.vertical{
    border-left: 3px solid #ff0000;
    height: auto;
}

.overlay{
    border-radius: 25px;
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #c50000;
}

.text{
    color: white;
    font-weight: 900;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center; 
}

.line_card{
    transform: scaleX(0);
    transition: all 0.3s ease-in-out;
}
.card:hover .line_card{
    transform: scaleX(1);
}

.card:hover .overlay{
    opacity: 1;
}
.fadeIn{
    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1s; /* Firefox < 16 */
     -ms-animation: fadein 1s; /* Internet Explorer */
         animation: fadein 1s;
}


@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}



@media only screen and (max-width: 767px){
    .card-img-top{
        height: 50vw !important;
    }
}

@media only screen and (max-width: 768px){
    .titleText{
       font-size: medium;
    }
}

@media only screen and (max-width: 576px){
    .line1{
       display: none
    }
    .line2{
       margin-top: 0 !important;
       margin-bottom: 40px;
     }
}


@media only screen and (max-width: 425px){
     .cardText{
        font-size: medium;
     }
}


@media only screen and (min-width: 1920px){
    .card-img-top{
        height: 19vw !important;
    }
}





