.bg{
    width: 100%;
    height: auto;
   }
.heroBanner{
    position: relative;
}
.overlayText{
    position: absolute;
    height: 20%;
    width: 100%;
    bottom: 0;
    background: rgba(0, 0, 0, 0.45);
    color: white;
}
.bold{
    font-weight: bold;
 }

.circle_dot{
  height: 30px;
  width: 30px;
  background-color: #E4572E;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
}

.feature_padding{
padding: 100px;
}
 .deck{
    background:#E4572E;
    color:#fff;
    border:none;
    position:relative;
    height:55px;
    font-size:1.2em;
    padding:0 2em;
    cursor:pointer;
    transition:800ms ease all;
    outline:none;
  }
  .deck:hover{
    background:rgb(226, 226, 226);
    color:#E4572E;
  }
  .deck:hover:before,.deck:hover:after{
    width:100%;
    transition:800ms ease all;
  }

.fadeIn{
    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1s; /* Firefox < 16 */
     -ms-animation: fadein 1s; /* Internet Explorer */
         animation: fadein 1s;
  }

@keyframes fadein {
from { opacity: 0; }
to   { opacity: 1; }
}

@media screen and (max-width: 769px){
  .IA_Pic, .lo_fi_pic, .hi_fi_pic,.mockup_pic{
    padding: 1rem !important;
  }
}
@media screen and (max-width: 992px){
  .feature_padding{
    padding: 50px !important;
  }
}

@media screen and (max-width: 425px){
  .feature_work{
    padding: 0.75rem; 
  }
}

