*{
    line-height: 20pt;
}
.bg{
 width: 100%;
 height: auto;
}
.heroBanner{
  position: relative;
}
.overlayText{
  position: absolute;
  height: 20%;
  width: 100%;
  bottom: 0;
  background: rgba(0, 0, 0, 0.45);
  color: white;
}

.bold{
   font-weight: bold;
}


.space{
    padding-top: 150px;
    padding-bottom: 150px;
}

.spacetp{
  padding-top: 150px;
}
.spacebt{
  padding-bottom: 150px;
}

video{
  width: 100%;
  height: auto;
}

.back-to-top {
  position: fixed;
  bottom: 40px;
  right: 25px;
  font-size: 55px;
  color: red;
  cursor: pointer;
}

.back-to-top:hover {
  color: rgb(150, 0, 0)
}


.playbtn, button::after {
  width: 280px;
  height: 86px;
  font-size: 36px;
  background: linear-gradient(45deg, transparent 5%, #000000 5%);
  border: 0;
  color: #fff;
  letter-spacing: 3px;
  line-height: 88px;
  box-shadow: 6px 0px 0px #62f600;
  outline: transparent;
  position: relative;
}

.playbtn::after {
  --slice-0: inset(50% 50% 50% 50%);
  --slice-1: inset(80% -6px 0 0);
  --slice-2: inset(50% -6px 30% 0);
  --slice-3: inset(10% -6px 85% 0);
  --slice-4: inset(40% -6px 43% 0);
  --slice-5: inset(80% -6px 5% 0);
  
  content: 'Play Now';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, transparent 3%, #62f600 3%, #62f600 5%, #000000 5%);
  text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #00E6F6;
  clip-path: var(--slice-0);
}

.playbtn:hover::after {
  animation: 1s glitch;
  animation-timing-function: steps(2, end);
}

.view1{
  background:#62f600;
  color:black;
  border:none;
  position:relative;
  height:55px;
  font-size:1.2em;
  padding:0 2em;
  cursor:pointer;
  transition:800ms ease all;
  outline:none;
}
.view1:hover{
  background:rgb(226, 226, 226);
  color: black;
}
.view1:hover:before,.view1:hover:after{
  width:100%;
  transition:800ms ease all;
}

.fadeIn{
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
   -ms-animation: fadein 1s; /* Internet Explorer */
       animation: fadein 1s;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}


@keyframes glitch {
  0% {
    clip-path: var(--slice-1);
    transform: translate(-20px, -10px);
  }
  10% {
    clip-path: var(--slice-3);
    transform: translate(10px, 10px);
  }
  20% {
    clip-path: var(--slice-1);
    transform: translate(-10px, 10px);
  }
  30% {
    clip-path: var(--slice-3);
    transform: translate(0px, 5px);
  }
  40% {
    clip-path: var(--slice-2);
    transform: translate(-5px, 0px);
  }
  50% {
    clip-path: var(--slice-3);
    transform: translate(5px, 0px);
  }
  60% {
    clip-path: var(--slice-4);
    transform: translate(5px, 10px);
  }
  70% {
    clip-path: var(--slice-2);
    transform: translate(-10px, 10px);
  }
  80% {
    clip-path: var(--slice-5);
    transform: translate(20px, -10px);
  }
  90% {
    clip-path: var(--slice-1);
    transform: translate(-10px, 0px);
  }
  100% {
    clip-path: var(--slice-1);
    transform: translate(0);
  }
}

@media only screen and (max-width: 768px){
  .pic3{
    width: 85% !important;
  }
  .pic2{
    width: 100% !important;
  }
  .pic4{
    width: 100% !important;
  }
  .pic5{
    width: 100% !important;
  }
  .pic6{
    width: 100% !important;
  }
  .pic8{
    width: 100% !important;
  }
  .playbtn{
    width: 270px;
    height: 86px;
    font-size: 36px;
  }


}

@media only screen and (max-width: 425px){
  .pic3{
    width: 100% !important;
  }
  .playbtn{
    width: 260px;
    height: 80px;
    font-size: 26px;
  }

}

@media screen and (min-width: 0px) and (max-width: 768px) {
  .roleText { visibility: visible; }  /* show it on small screens */
  .testBanner {display: none;}
}

@media screen and (min-width: 769px){
  .roleText { display: none;}   /* hide it elsewhere */
}
