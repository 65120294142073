
@media only screen and (max-width: 576px){
   .footertext{
       font-size: medium;
   }
}
@media only screen and (max-width: 425px){
    .footertext{
        font-size: small;
    }
 }
