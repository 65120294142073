.deckCT{
    background:#DC143C;
    color:#fff;
    border:none;
    position:relative;
    height:55px;
    font-size:1.2em;
    padding:0 2em;
    cursor:pointer;
    transition:800ms ease all;
    outline:none;
  }
  .deckCT:hover{
    background:rgb(226, 226, 226);
    color:#DC143C;
  }
  .deckCT:hover:before,.deckCT:hover:after{
    width:100%;
    transition:800ms ease all;
  }

  .pic12{
    cursor: pointer;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    transition: transform .2s; 
  }
  .pic12:hover{
    transform: scale(1.01);
  }

  .container_CT {
    padding-right: 40px;
    padding-left: 40px;
    margin-right: auto;
    margin-left: auto;
  }
  @media (min-width: 768px) {
    .container_CT {
      width: 750px;
    }
   
  }
  @media (min-width: 992px) {
    .container_CT {
      width: 970px;
    }
  }
  @media (min-width: 1200px) {
    .container_CT {
      width: 1170px;
    }
  }
  @media (max-width: 768px){
    .Prototype{
      display: none;
    }

  }
  @media (max-width: 576px){
    .pic12{
      border-radius: 0px;
    }

  }
 